import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  supervisors:[],
  selectsupervisors: null,
};

const slice = createSlice({
  name: "supervisor",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SUPERVISORS
    getSupervisorSuccess(state, action) {
      state.isLoading = false;
      state.supervisors = action.payload;
    },
    // GET SUPERVISORS TO SELECT
    getSupervisorToSelectSuccess(state, action) {
      state.isLoading = false;
      state.selectsupervisors = action.payload;
    },

    // ADD SUPERVISOR
    addSupervisorSuccess(state, action) {
      const supervisor = action.payload;
      state.supervisors.data.push(supervisor);
      state.supervisors.count += 1;
    },

    // UPDATE SUPERVISOR
    updateSupervisorSuccess(state, action) {
      const supervisor = action.payload;
      if (state.supervisors && state.supervisors.data) {
        const supervisorIndex = state.supervisors?.data?.findIndex(
          (obj) => obj.id === supervisor.id
        );
        if (supervisorIndex !== -1) {
          state.isLoading = false;
          state.supervisors.data[supervisorIndex] = supervisor;
        }
      } else {
        state.supervisors = { data: [] };
        state.supervisors.data.push(supervisor);
      }
    },

    // DELETE SUPERVISOR
    deleteSupervisorSuccess(state, action) {
      const deletedSupervisorId = action.payload;
      state.supervisors.data = state.supervisors.data.filter(
        (supervisor) => supervisor.id !== deletedSupervisorId
      );
      state.isLoading = false;
    },

    // DELETE SUPERVISORS
    deleteSupervisorsSuccess(state, action) {
      const deletedSupervisorIds = action.payload;
      state.supervisors.data = state.supervisors.data.filter(
        (supervisor) => !deletedSupervisorIds.includes(supervisor.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addSupervisorSuccess,
  updateSupervisorSuccess,
  deleteSupervisorSuccess,
  deleteSupervisorsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getSupervisors() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/supervisors/`
      );
      dispatch(slice.actions.getSupervisorSuccess(response.data));
      console.log('response.data response.data',response.data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




export async function getSupervisor(keyId) {
  const response = await axios.get(
    `/api/supervisors/${keyId}`
  );
  return response.data;
}

export async function addSupervisor(data) {
  const response = await axios.post(`/api/supervisors/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function updateSupervisor(id,data) {
  const response = await axios.put(`/api/supervisors/${id}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function deleteSupervisor(keyId) {
  const response = await axios.delete(`/api/supervisors/${keyId}/`);
  return response.data;
}

export async function deleteSupervisors(keyIds) {
  const response = await axios.delete(`/api/supervisor-id`, {
    data: { keyIds },
  });
  return response.data;
}
