import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  drivers: [],
};

const slice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET VENDORS
    getDriverSuccess(state, action) {
      state.isLoading = false;
      state.drivers = action.payload;
    },

    // ADD VENDOR
  



  }
 
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addVendorSuccess,
  updateVendorSuccess,
 

} = slice.actions;

// ----------------------------------------------------------------------

export function getDrivers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/drivers/`
      );
      dispatch(slice.actions.getDriverSuccess(response.data));
      console.log('response.data',response.data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getDriver(keyId) {
  const response = await axios.get(`/api/drivers/${keyId}/`);
  return response.data;
}

export async function addDriver(data) {
  const response = await axios.post(`/api/drivers/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}



export async function updateDriver(id,data) {
  const response = await axios.put(`/api/drivers/${id}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function deleteDriver(keyId) {
  const response = await axios.delete(`/api/drivers/${keyId}/`);
  return response.data;
}


