// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgIconStyle from "../../../components/SvgIconStyle";
import Label from "../../../components/Label";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dashboard: getIcon("ic_dashboard"),
  supervisor: getIcon("ic_supervisor"),
  vendor: getIcon("ic_vendor"),
  succursale: getIcon("ic_succursale"),
  customer: getIcon("ic_customer"),
  configuration: getIcon("ic_configuration"),
  security_and_limit: getIcon("ic_security_and_limit"),
  ticket: getIcon("ic_ticket"),
  report: getIcon("ic_report"),
  transaction: getIcon("ic_transaction"),
  user: getIcon("ic_user"),
  container:getIcon("ic_container"),
  driver:getIcon("ic_driver"),
  truck:getIcon("ic_truck"),
  check:getIcon("ic_check"),
  route:getIcon("ic_route"),
  facture:getIcon("ic_invoice")
};

const NavConfig = () => {
  const { t } = useTranslation();
  const navConfig = [
    // OVERVIEW
    {
      subheader: t("sideBar.OVERVIEW"),
      items: [
        {
          title: t("params.dashboard"),
          path: PATH_DASHBOARD.general.app,
          icon: ICONS.dashboard,
        },
      ],
    },
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: t("sideBar.MANAGEMENT"),
      items: [
        {
          title: t("sideBar.supervisor"),
          path: PATH_DASHBOARD.supervisor.root,
          icon: ICONS.supervisor,
          perm: "view_supervisor",
          children: [
            {
              title: t("sideBar.add_supervisor"),
              path: PATH_DASHBOARD.supervisor.add,
              perm: "add_supervisor",
            },
            {
              title: t("sideBar.list_supervisor"),
              path: PATH_DASHBOARD.supervisor.list,
              perm: "view_supervisor",
            },
          ],
        },

        {
          title: t("sideBar.vendor"),
          path: PATH_DASHBOARD.vendor.root,
          icon: ICONS.vendor,
          perm: "view_client",
          children: [
            {
              title: t("sideBar.add_vendor"),
              path: PATH_DASHBOARD.vendor.add,
              perm: "add_client",
            },
            {
              title: t("sideBar.list_vendor"),
              path: PATH_DASHBOARD.vendor.list,
              perm: "view_client",
            },
          ],
        },
        
        {
          title: t("sideBar.succursale"),
          path: PATH_DASHBOARD.succursale.root,
          icon: ICONS.container,
          perm: "view_container",
          children: [
            {
              title: t("sideBar.add_succursale"),
              path: PATH_DASHBOARD.succursale.add,
              perm: "add_container",
            },
            {
              title: t("sideBar.list_succursale"),
              path: PATH_DASHBOARD.succursale.list,
              perm: "view_container",
            },
          ],
        },
       

        {
          title: t("sideBar.driver"),
          path: PATH_DASHBOARD.driver.root,
          icon: ICONS.driver,
          perm: "view_driver",
          children: [
            {
              title: t("sideBar.add_driver"),
              path: PATH_DASHBOARD.driver.add,
              perm: "add_driver",
            },
            {
              title: t("sideBar.list_driver"),
              path: PATH_DASHBOARD.driver.list,
              perm: "view_driver",
            },
          ],
        },

        
        {
          title: t("sideBar.camion"),
          path: PATH_DASHBOARD.camion.root,
          icon: ICONS.truck,
          perm: "view_camion",
          children: [
            {
              title: t("sideBar.add_camion"),
              path: PATH_DASHBOARD.camion.add,
              perm: "add_camion",
            },
            {
              title: t("sideBar.list_camion"),
              path: PATH_DASHBOARD.camion.list,
              perm: "view_camion",
            },
          ],
        },

        
        {
          title: t("sideBar.trajet"),
          path: PATH_DASHBOARD.trajet.root,
          icon: ICONS.route,
          perm: "view_route",
          children: [
            {
              title: t("sideBar.add_trajet"),
              path: PATH_DASHBOARD.trajet.add,
              perm: "add_route",
            },
            {
              title: t("sideBar.list_trajet"),
              path: PATH_DASHBOARD.trajet.list,
              perm: "view_route",
            },
          ],
        },

        {
          title: t("sideBar.checkpoint"),
          path: PATH_DASHBOARD.checkpoint.root,
          icon: ICONS.check,
          perm: "view_checkpoint",
          children: [
            {
              title: t("sideBar.add_checkpoint"),
              path: PATH_DASHBOARD.checkpoint.add,
              perm: "add_checkpoint",
            },
            {
              title: t("sideBar.list_checkpoint"),
              path: PATH_DASHBOARD.checkpoint.list,
              perm: "view_checkpoint",
            },
          ],
        },
      ],
    },
    
    {
      subheader: t("sideBar.facture"),
      items: [
        // USER
        {
          title: t("sideBar.facture"),
          path: PATH_DASHBOARD.facture.root,
          icon: ICONS.facture,
          children: [
            {
              title: t("sideBar.add_facture"),
              path: PATH_DASHBOARD.facture.add,
              perm: "view_report_vente",
            },
            {
              title: t("sideBar.list_facture"),
              path: PATH_DASHBOARD.facture.rapport,
              perm: "view_report_user",
            },
          ],
        },
      ],
    },

    {
      subheader: t("sideBar.REPORT"),
      items: [
        // USER
        {
          title: t("sideBar.report"),
          path: PATH_DASHBOARD.report.root,
          icon: ICONS.report,
          children: [
            {
              title: t("sideBar.report_sale"),
              path: PATH_DASHBOARD.report.sale,
              perm: "view_report_vente",
            },
            // {
            //   title: t("sideBar.report_user"),
            //   path: PATH_DASHBOARD.report.user,
            //   perm: "view_report_user",
            // },
          ],
        },
      ],
    },
    // PARAMETTRE
    // ----------------------------------------------------------------------
    // {
    //   subheader: t("sideBar.PARAMETERS"),
    //   items: [
    //     // USER
    //     {
    //       title: t("sideBar.user"),
    //       path: PATH_DASHBOARD.user.root,
    //       icon: ICONS.user,
    //       children: [
    //         { title: t("sideBar.edit"), path: PATH_DASHBOARD.user.account },
    //       ],
    //     },
    //   ],
    // },
  ];
  return navConfig;
};

export default NavConfig;
