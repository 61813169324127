import React, { createContext } from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
// import { WSS_HOST_API } from "../config";
import { Howl } from "howler";
import weyyYa from "../assets/sounds/connect.wav";

const initialState = {
  isConnected: false,
  isInitialized: false,
};

const SocketContext = createContext({
  ...initialState,
  closeWebSocket: () => {},
});

class SocketProviderClass extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.webSocket = null;
    this.closeWebSocket = this.closeWebSocket.bind(this);
    this.sound = new Howl({
      src: [weyyYa],
    });
  }

  // componentDidMount() {
  //   this.accessToken = window.localStorage.getItem("accessToken");
  //   if (this.accessToken != null) {
  //     this.webSocket = new WebSocket(
  //       WSS_HOST_API + `/user_connect?token=${this.accessToken}`
  //     );

  //     this.webSocket.onopen = () => {
  //       this.setState({
  //         isConnected: true,
  //         isInitialized: true,
  //       });
  //     };

  //     this.webSocket.onmessage = (event) => {
  //       const data = JSON.parse(event.data);
  //     };

  //     this.webSocket.onclose = () => {
  //       this.setState({
  //         isConnected: false,
  //         isInitialized: true,
  //       });
  //     };
  //   }
  // }

  componentWillUnmount() {
    this.webSocket.close();
  }

  loadFriends = () => {
    this.webSocket.send(
      JSON.stringify({
        message: "new_connection",
      })
    );
  };

  closeWebSocket = () => {
    if (this.webSocket) {
      this.webSocket.close();
    }
  };

  render() {
    const { children } = this.props;
    return (
      <SocketContext.Provider
        value={{
          ...this.state,
          closeWebSocket: this.closeWebSocket,
        }}
      >
        {children}
      </SocketContext.Provider>
    );
  }
}
const SocketProvider = withSnackbar(SocketProviderClass);
export { SocketContext, SocketProvider };
