import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


export default function RHFDocuments({ documents })  {
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
    return (
      <>
        {/* Bouton principal pour ouvrir la liste des documents */}
        <Button variant="outlined" onClick={handleOpen}>
          Documents
        </Button>
  
        {/* Modale pour afficher les documents */}
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>Documents du Conteneur</DialogTitle>
          <DialogContent dividers>
            {documents.length > 0 ? (
              documents.map((doc, index) => (
                <div key={doc.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <Typography variant="body2" style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {doc.file.split('/').pop()} {/* Affiche uniquement le nom du fichier */}
                  </Typography>
                  <IconButton
                    component="a"
                    href={doc.file}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Open ${doc.file.split('/').pop()}`}
                  >
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </div>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                Aucun document disponible
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  
  