import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, Button, Typography } from "@mui/material";

const RHFUploadFile = ({ name, label, accept }) => {
  const { control, watch } = useFormContext();
  const files = watch(name);

  return (
    <FormControl fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{ mt: 2 }}
            >
              {files && files.length > 0
                ? `${files.length} fichier(s) sélectionné(s)`
                : "Sélectionnez des fichiers"}
              <input
                type="file"
                hidden
                multiple
                accept={accept}
                onChange={(e) => field.onChange(e.target.files)}
              />
            </Button>
            {/* Liste des noms de fichiers sélectionnés */}
            {files && files.length > 0 && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                {Array.from(files).map((file, index) => (
                  <div key={index}>{file.name}</div>
                ))}
              </Typography>
            )}
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
      />
    </FormControl>
  );
};

export default RHFUploadFile;
