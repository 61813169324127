import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  limit_games: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "limitgame",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LIMIT GAME
    getLimitGameSuccess(state, action) {
      state.isLoading = false;
      state.limit_games = action.payload;
    },

    // ADD LIMIT GAME
    addLimitGameSuccess(state, action) {
      const limitgame = action.payload;
      state.limit_games.data.push(limitgame);
      state.limit_games.count += 1;
    },

    // UPDATE LIMIT GAMES
    updateLimitGameSuccess(state, action) {
      const limitgame = action.payload;
      if (state.limit_games && state.limit_games.data) {
        const limitgameIndex = state.limit_games?.data?.findIndex(
          (obj) => obj.id === limitgame.id
        );
        if (limitgameIndex !== -1) {
          state.isLoading = false;
          state.limit_games.data[limitgameIndex] = limitgame;
        }
      } else {
        state.limit_games = { data: [] };
        state.limit_games.data.push(limitgame);
      }
    },

    // DELETE LIMIT GAME
    deleteLimitGameSuccess(state, action) {
      const deletedLimitGameId = action.payload;
      state.limit_games.data = state.limit_games.data.filter(
        (limitgame) => limitgame.id !== deletedLimitGameId
      );
      state.isLoading = false;
    },

    // DELETE LIMIT GAMES
    deleteLimitGamesSuccess(state, action) {
      const deletedLimitGameIds = action.payload;
      state.limit_games.data = state.limit_games.data.filter(
        (limitgame) => !deletedLimitGameIds.includes(limitgame.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addLimitGameSuccess,
  updateLimitGameSuccess,
  deleteLimitGameSuccess,
  deleteLimitGamesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLimitGames(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard/limit-game?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getLimitGameSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getLimitGame(keyId) {
  const response = await axios.get(
    `/api/dashboard/limit-game-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addLimitGame(data) {
  const response = await axios.post(`/api/dashboard/limit-game`, data);
  return response.data;
}

export async function updateLimitGame(data) {
  const response = await axios.put(`/api/dashboard/limit-game`, data);
  return response.data;
}

export async function deleteLimitGame(keyId) {
  const response = await axios.delete(`/api/dashboard/limit-game/${keyId}/`);
  return response.data;
}

export async function deleteLimitGames(keyIds) {
  const response = await axios.delete(`/api/dashboard/limit-game-id`, {
    data: { keyIds },
  });
  return response.data;
}
