import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  checkpoints: [],

};

const slice = createSlice({
  name: "checkpoint",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET VENDORS
    getCheckpointsSuccess(state, action) {
      state.isLoading = false;
      state.checkpoints = action.payload;
    },

  

  
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
//   addVendorSuccess,
//   updateVendorSuccess,
//   deleteVendorSuccess,
//   deleteVendorsSuccess,
// } = slice.actions;

// ----------------------------------------------------------------------

export function getCheckpoints() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/checkpoints/`
      );
      dispatch(slice.actions.getCheckpointsSuccess(response.data));
      console.log('response.data',response.data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export async function getCheckpoint(keyId) {
  const response = await axios.get(`/api/checkpoints/${keyId}/`);
  return response.data;
}

export async function addCheckpoints(data) {
  const response = await axios.post(`/api/checkpoints/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}


export async function updateCheckpoints(id,data) {
  const response = await axios.put(`/api/checkpoints/${id}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function deleteCheckpoints(keyId) {
  const response = await axios.delete(`/api/checkpoints/${keyId}/`);
  return response.data;
}

