import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  countries: [],
  currencies: [],
  genres: [],
  maritals: [],
  usertypeids: [],
  typetransactions: [],
  tirages: [],
  options: [],
  users: [],
  appreport: [],
  applotgagnant: [],
  apptodaylotgagnant: [],
  model_pos: [],
};

const slice = createSlice({
  name: "generale",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COUNTRIES
    getCountriesSuccess(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    },

    // GET CURRENCIES
    getCurrenciesSuccess(state, action) {
      state.isLoading = false;
      state.currencies = action.payload;
    },

    // GET GENRES
    getGenresSuccess(state, action) {
      state.isLoading = false;
      state.genres = action.payload;
    },

    // GET MARITALS
    getMaritalsSuccess(state, action) {
      state.isLoading = false;
      state.maritals = action.payload;
    },

    // GET TYPEIDS
    getTypeIdsSuccess(state, action) {
      state.isLoading = false;
      state.usertypeids = action.payload;
    },

    // GET TYPETRANSACTIONS
    getTypeTransactionsSuccess(state, action) {
      state.isLoading = false;
      state.typetransactions = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET TIRAGES
    getTiragesSuccess(state, action) {
      state.isLoading = false;
      state.tirages = action.payload;
    },

    // GET OPTIONS
    getOptionsSuccess(state, action) {
      state.isLoading = false;
      state.options = action.payload;
    },

    // GET APP LOT GAGNANTS
    getAppLotGagnantsSuccess(state, action) {
      state.isLoading = false;
      state.applotgagnant = action.payload;
    },

    // GET TODAY LOT GAGNANTS
    getAppTodayLotGagnantsSuccess(state, action) {
      state.isLoading = false;
      state.apptodaylotgagnant = action.payload;
    },

    // GET APP REPORT
    getAppReportsSuccess(state, action) {
      state.isLoading = false;
      state.appreport = action.payload;
    },

    // GET MODEL POS
    getModelPosSuccess(state, action) {
      state.isLoading = false;
      state.model_pos = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCountries() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/countries");
      dispatch(slice.actions.getCountriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrencies() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/currencies");
      dispatch(slice.actions.getCurrenciesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGenres() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/genres");
      dispatch(slice.actions.getGenresSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMaritals() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/maritals");
      dispatch(slice.actions.getMaritalsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTypeIds() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/typeids");
      dispatch(slice.actions.getTypeIdsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTypeTransactions() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/typetransactions");
      dispatch(slice.actions.getTypeTransactionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/users-to-select");
      dispatch(slice.actions.getUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTirages() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/tirages");
      dispatch(slice.actions.getTiragesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOptions() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/options");
      dispatch(slice.actions.getOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getModelPos() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/model-pos-to-select");
      dispatch(slice.actions.getModelPosSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAppLotGagnant() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/dashboard/app-lot-gagnant");
      dispatch(slice.actions.getAppLotGagnantsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAppTodayLotGagnant() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/dashboard/today-lotgagnant");
      dispatch(slice.actions.getAppTodayLotGagnantsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAppReport() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/dashboard/app-report");
      dispatch(slice.actions.getAppReportsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
