import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  routes: [],
  routeFalses:[],
  routeWithoutOrders:[]
};

const slice = createSlice({
  name: "route",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET VENDORS
    getRouteSuccess(state, action) {
      state.isLoading = false;
      state.routes = action.payload;
    },

    getRouteNoLivrateSuccess(state, action) {
      state.isLoading = false;
      state.routeFalses = action.payload;
    },
    

    getRouteWithoutOrderSuccess(state, action) {
      state.isLoading = false;
      state.routeWithoutOrders = action.payload;
    },
    

  
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
//   addVendorSuccess,
//   updateVendorSuccess,
//   deleteVendorSuccess,
//   deleteVendorsSuccess,
// } = slice.actions;

// ----------------------------------------------------------------------

export function getRoutes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/routes/`
      );
      dispatch(slice.actions.getRouteSuccess(response.data));
      console.log('response.data',response.data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getRouteNoLivrates() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/unlivred-routes/`
      );
      dispatch(slice.actions.getRouteNoLivrateSuccess(response.data));
      console.log('response.data',response.data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getRouteWithoutOrders() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/routes-without-orders/`
      );
      dispatch(slice.actions.getRouteWithoutOrderSuccess(response.data));
      console.log('response.data',response.data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export async function getRoute(keyId) {
  const response = await axios.get(`/api/routes/${keyId}/`);
  return response.data;
}

export async function addRoute(data) {
  const response = await axios.post(`/api/routes/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}


export async function updateRoute(id,data) {
  const response = await axios.put(`/api/routes/${id}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function deleteRoute(keyId) {
  const response = await axios.delete(`/api/routes/${keyId}/`);
  return response.data;
}

