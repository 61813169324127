import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  reports: null,
  factureReport:null
};

const slice = createSlice({
  name: "report",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET REPORTS
    getReportsSuccess(state, action) {
      state.isLoading = false;
      state.reports = action.payload;
    },

    getFacturesSuccess(state, action) {
      state.isLoading = false;
      state.factureReport = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReports(
  filterStartDate,
  filterEndDate,
  filterTirage,
  filterSuccursale,
  filterUser
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/list/reports?start_date=${filterStartDate}&end_date=${filterEndDate}&container_id=${filterSuccursale}&customer_id=${filterTirage}`
      );
      dispatch(slice.actions.getReportsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getFactures(
  filterStartDate,
  filterEndDate,
  filterTirage,
  // filterSuccursale,
  filterUser
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/list/client-orders?start_date=${filterStartDate}&end_date=${filterEndDate}&customer_id=${filterTirage}`
      );
      dispatch(slice.actions.getFacturesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

