import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  camions: [],
  camionAvailables: [],
};

const slice = createSlice({
  name: "camion",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET VENDORS
    getCamionSuccess(state, action) {
      state.isLoading = false;
      state.camions = action.payload;
    },

    getCamionAvailableSuccess(state, action) {
      state.isLoading = false;
      state.camionAvailables = action.payload;
    },
    

  
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
//   addVendorSuccess,
//   updateVendorSuccess,
//   deleteVendorSuccess,
//   deleteVendorsSuccess,
// } = slice.actions;

// ----------------------------------------------------------------------

export function getCamions() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/camions/`
      );
      dispatch(slice.actions.getCamionSuccess(response.data));
      console.log('response.data',response.data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCamionAvailables() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/available-camions/`
      );
      dispatch(slice.actions.getCamionAvailableSuccess(response.data));
      console.log('response.data',response.data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export async function getCamion(keyId) {
  const response = await axios.get(`/api/camions/${keyId}/`);
  return response.data;
}

export async function addCamion(data) {
  const response = await axios.post(`/api/camions/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}


export async function updateCamion(id,data) {
  const response = await axios.put(`/api/camions/${id}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function deleteCamion(keyId) {
  const response = await axios.delete(`/api/camions/${keyId}/`);
  return response.data;
}

