import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  ticketwins: { count: 0, data: [], num_pages: 1, page: 1, page_size: "5" },
};

const slice = createSlice({
  name: "ticketwin",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TICKET WINS
    getTicketWinsSuccess(state, action) {
      state.isLoading = false;
      state.ticketwins = action.payload;
    },

    // DELETE TICKET WIN
    deleteTicketWinSuccess(state, action) {
      const deletedTicketWinId = action.payload;
      state.ticketwins.data = state.ticketwins.data.filter(
        (ticketwin) => ticketwin.id !== deletedTicketWinId
      );
      state.isLoading = false;
    },

    // DELETE TICKET WINS
    deleteTicketWinsSuccess(state, action) {
      const deletedTicketWinIds = action.payload;
      state.ticketwins.data = state.ticketwins.data.filter(
        (ticketwin) => !deletedTicketWinIds.includes(ticketwin.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteTicketWinSuccess, deleteTicketWinsSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getTicketWins(
  page = 1,
  rowsPerPage = 5,
  filterStartDate,
  filterEndDate,
  filterTirage,
  filterSuccursale,
  filterUser
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard/tickets-won?page=${page}&page_size=${rowsPerPage}&start_date=${filterStartDate}&end_date=${filterEndDate}&tirages=${filterTirage}&succursale=${filterSuccursale}&user=${filterUser}`
      );
      dispatch(slice.actions.getTicketWinsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getTicketWin(keyId) {
  const response = await axios.get(`/api/dashboard/ticket-id?keyId=${keyId}`);
  return response.data;
}

export async function deleteTicketWin(keyId) {
  const response = await axios.delete(`/api/dashboard/tickets/${keyId}/`);
  return response.data;
}

export async function deleteTicketWins(keyIds) {
  const response = await axios.delete(`/api/dashboard/ticket-id`, {
    data: { keyIds },
  });
  return response.data;
}
