import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  factures: [],

};

const slice = createSlice({
  name: "facture",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET VENDORS
    getCheckpointsSuccess(state, action) {
      state.isLoading = false;
      state.checkpoints = action.payload;
    },

  

  
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
//   addVendorSuccess,
//   updateVendorSuccess,
//   deleteVendorSuccess,
//   deleteVendorsSuccess,
// } = slice.actions;

// ----------------------------------------------------------------------

export function getFactures() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/list/orders/`
      );
      dispatch(slice.actions.getFacturesSuccess(response.data));
      console.log('response.data',response.data)
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export async function getFacture(keyId) {
  const response = await axios.get(`/api/list/orders/${keyId}/`);
  return response.data;
}

export async function addFactures(data) {
  const response = await axios.post(`/api/list/orders/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}


export async function updateFactures(id,data) {
  const response = await axios.put(`/api/list/orders/${id}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function deleteFactures(keyId) {
  const response = await axios.delete(`/api/list/orders/${keyId}/`);
  return response.data;
}

