import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import Blank from "../layouts/Blank";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import MainHeader from "src/layouts/main/MainHeader";


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      element: <MainLayout />,
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "home", element: <GeneralApp /> },
        
        {
          path: "master-vendor",
          children: [
            {
              element: <Navigate to="/dashboard/master-vendor/list" replace />,
              index: true,
            },
            { path: "list", element: <SupervisorList /> },
            { path: "new", element: <SupervisorCreate /> },
            { path: ":supervisorKey/edit", element: <SupervisorCreate /> },
          ],
        },
        // succursales
        {
          path: "succursale",
          children: [
            {
              element: <Navigate to="/dashboard/succursale/list" replace />,
              index: true,
            },
            { path: "list", element: <SuccursaleList /> },
            { path: "new", element: <SuccursaleCreate /> },
            { path: ":succursaleKey/edit", element: <SuccursaleCreate /> },
          ],
        },
        // vendors
        {
          path: "vendor",
          children: [
            {
              element: <Navigate to="/dashboard/vendor/list" replace />,
              index: true,
            },
            { path: "list", element: <VendorList /> },
            { path: "new", element: <VendorCreate /> },
            { path: ":vendorKey/edit", element: <VendorCreate /> },
          ],
        },

        {
          path: "driver",
          children: [
            {
              element: <Navigate to="/dashboard/driver/list" replace />,
              index: true,
            },
            { path: "list", element: <DriverList /> },
            { path: "new", element: <DriverCreate /> },
            { path: ":vendorKey/edit", element: <DriverCreate /> },
          ],
        },
       
        {
          path: "camion",
          children: [
            {
              element: <Navigate to="/dashboard/camion/list" replace />,
              index: true,
            },
            { path: "list", element: <CamionList /> },
            { path: "new", element: <CamionCreate /> },
            { path: ":vendorKey/edit", element: <CamionCreate /> },
          ],
        },

        {
          path: "trajet",
          children: [
            {
              element: <Navigate to="/dashboard/trajet/list" replace />,
              index: true,
            },
            { path: "list", element: <TrajetList /> },
            { path: "new", element: <TrajeCreate /> },
            { path: ":vendorKey/edit", element: <TrajeCreate /> },
          ],
        },

        {
          path: "facture",
          children: [
            {
              element: <Navigate to="/dashboard/facture/list" replace />,
              index: true,
            },
            // { path: "list", element: <FactureList /> },
            { path: "rapport", element: <FactureReport /> },
            { path: "new", element: <FactureCreate /> },
            { path: ":vendorKey/edit", element: <TrajetList /> },
          ],
        },



        {
          path: "checkpoint",
          children: [
            {
              element: <Navigate to="/dashboard/checkpoint/list" replace />,
              index: true,
            },
            { path: "list", element: <CheckpointList /> },
            { path: "new", element: <CheckpointCreate /> },
            { path: ":vendorKey/edit", element: <CheckpointCreate /> },
          ],
        },
        {
          path: "report",
          children: [
            {
              element: <Navigate to="/dashboard/report/sale" replace />,
              index: true,
            },
            { path: "sale", element: <Report /> },
            { path: "user", element: <ReportUser /> },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/account" replace />,
              index: true,
            },
            { path: "account", element: <UserAccount /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "politique-confidentialite", element: <PolitiqueConf /> },
        { path: "termes-conditions", element: <TermesConditions /> },
        {
          path: "politique-remboursement",
          element: <PolitiqueRemboursement />,
        },
        {
          path: "politique-moderation",
          element: <PolitiqueModeration />,
        },
        {
          path: "conditions-affiliation",
          element: <ConditionsAffiliation />,
        },
        {
          path: "politique-securite",
          element: <PolitiqueSecurite />,
        },
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
               <HomePage />
            </GuestGuard>
          ),
          index: true,
        },
        { path: "about-us", element: <About /> },
        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <Faqs /> },
      ],
    },

   
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);


// // REPORT
const Report = Loadable(
  lazy(() => import("../pages/dashboard/Report/ReportInit"))
);

const FactureReport = Loadable(
  lazy(() => import("../pages/dashboard/Report/ReportInitfacture"))
);


// REPORT AGENT
const ReportUser = Loadable(
  lazy(() => import("../pages/dashboard/ReportUsers/ReportInit"))
);

// STATISTIQUE
// // const Statistique = Loadable(
// //   lazy(() => import("../pages/dashboard/Statistique"))
// );

// SUPERVISOR
const SupervisorList = Loadable(
  lazy(() => import("../pages/dashboard/SupervisorList"))
);

const DriverList = Loadable(
  lazy(() => import("../pages/dashboard/DriverList"))
);

const TrajetList = Loadable(
  lazy(() => import("../pages/dashboard/TrajetList"))
);

const TrajeCreate = Loadable(
  lazy(() => import("../pages/dashboard/TrajetCreate"))
);


const FactureCreate = Loadable(
  lazy(() => import("../pages/dashboard/FactureCreate"))
);


const FactureList = Loadable(
  lazy(() => import("../pages/dashboard/FactureList"))
);

const CheckpointCreate = Loadable(
  lazy(() => import("../pages/dashboard/CheckpointCreate"))
);


const CheckpointList = Loadable(
  lazy(() => import("../pages/dashboard/CheckpointList"))
);


const CamionList = Loadable(
  lazy(() => import("../pages/dashboard/CamionList"))
);

const SupervisorCreate = Loadable(
  lazy(() => import("../pages/dashboard/SupervisorCreate"))
);

// SUCCURSALE
const SuccursaleList = Loadable(
  lazy(() => import("../pages/dashboard/SuccursaleList"))
);

const SuccursaleCreate = Loadable(
  lazy(() => import("../pages/dashboard/SuccursaleCreate"))
);
// VENDOR
const VendorList = Loadable(
  lazy(() => import("../pages/dashboard/VendorList"))
);

const VendorCreate = Loadable(
  lazy(() => import("../pages/dashboard/VendorCreate"))
);


const DriverCreate = Loadable(
  lazy(() => import("../pages/dashboard/DriverCreate"))
);


const CamionCreate = Loadable(
  lazy(() => import("../pages/dashboard/CamionCreate"))
);
// USER
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
// MAIN
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const PolitiqueConf = Loadable(lazy(() => import("../pages/PolitiqueConf")));
const TermesConditions = Loadable(
  lazy(() => import("../pages/TermesConditions"))
);
const PolitiqueRemboursement = Loadable(
  lazy(() => import("../pages/PolitiqueRemboursement"))
);
const PolitiqueModeration = Loadable(
  lazy(() => import("../pages/PolitiqueModeration"))
);
const ConditionsAffiliation = Loadable(
  lazy(() => import("../pages/ConditionsAffiliation"))
);
const PolitiqueSecurite = Loadable(
  lazy(() => import("../pages/PolitiqueSecurite"))
);
const Faqs = Loadable(lazy(() => import("../pages/Faqs")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
